<template>
  <div
    class="bg-nav_color text-text_alt_color text-sm xs:text-base py-1 sm:py-5"
  >
    <div v-if="store" class="e_store-container flex items-center">
      <EStoreLink to="/" class="mx-1" :aria-label="store.name">
        <!-- Logo -->
        <NuxtImg
          provider="cacheIpx"
          preset="modified"
          loading="lazy"
          :src="store.icon"
          width="48"
          height="48"
          class="w-10 h-10 sm:w-12 sm:h-12 object-contain rounded"
          :alt="store.name"
        />
      </EStoreLink>
      <EStoreLink
        v-for="(link, index) in links"
        :key="`links-store-${index}`"
        :to="link.route"
        class="navbar-action"
        :aria-label="t('aria_page_label', { page: link.title })"
      >
        <div class="navbar-link">{{ link.title }}</div>
      </EStoreLink>

      <EStoreLink
        v-if="isMobileOrTablet"
        to="/"
        class="navbar-action"
        :aria-label="store?.name"
      >
        <div class="navbar-link">{{ store.name }}</div>
      </EStoreLink>

      <div class="flex-auto"></div>

      <RikazAction
        v-if="store?.cart_visible && !isMobileOrTablet"
        route="/cart"
        :has-badge="true"
        class="navbar-action"
        :transition-key="cart.badgeCount"
        position="top-0 ltr:left-1 rtl:right-1"
        :label="t('aria_page_label', { pae: t('cart') })"
      >
        <div class="navbar-link">
          <v-icon icon="fa: fa-solid fa-cart-shopping" :size="24" />
          <div class="navbar-title">{{ t('cart') }}</div>
        </div>

        <template #badge>
          <ClientOnly>
            <div
              v-if="cart.badgeCount"
              class="px-1 h-4 text-xs text-text_color rounded-full bg-bg_alt_color"
            >
              {{ cart.badgeCount }}
            </div>
          </ClientOnly>
        </template>
      </RikazAction>
      <div class="flex justify-center items-center">
        <LayoutNavbarNotification v-if="supportUserLogin" />
        <RikazAction
          v-if="supportUserLogin"
          route="/my-account"
          label="profile page"
        >
          <div class="navbar-action">
            <div class="navbar-link">
              <v-icon icon="mdi mdi-account-outline" :size="30" />
            </div>
          </div>
        </RikazAction>
        <LayoutNavbarLanguageLocales
          class-active-lang="bg-bg_color text-text_color bg-opacity-80"
          class-hover="hover:bg-bg_color hover:text-text_color hover:bg-opacity-80"
        />
        <Transition
          v-if="
            store?.cart_visible && isMobileOrTablet && routeIsForMaterialPage()
          "
          name="bounce"
        >
          <div :key="cart.items.length">
            <EStoreLink to="/cart">
              <div class="relative">
                <v-icon :size="40" class="text-text_color textShadow">
                  <IconsCartBottomNavigation />
                </v-icon>
                <div
                  class="absolute top-2 left-1/2 -translate-x-1/3 text-button_color mb-1 font-medium"
                >
                  {{ cart.badgeCount }}
                </div>
              </div>
            </EStoreLink>
          </div>
        </Transition>
      </div>

      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const cart = useCartStore()
const { store } = useDomainState()
const { settingsStore } = useStoresSettings()
const { routeIsForMaterialPage } = useMenuModel()
const supportUserLogin =
  store.value?.active_package?.package?.support_user_login
const { isMobileOrTablet } = useDevice()
const { t } = useI18n()
const links = computed(() => {
  const linksTemp = [
    { title: t('categories'), route: '/categories' },
    { title: t('offers_discounts'), route: '/offers' }
  ] as { title: string; route: string }[]

  settingsStore.value.forEach((el) => {
    linksTemp.push({
      route: `/about/${el.key}`,
      title: t(el.key)
    })
  })
  return isMobileOrTablet ? [] : linksTemp
})
onMounted(() => {
  nextTick(() => {
    if (store.value?.cart_visible) {
      cart.serverLoad()
    } else {
      cart.clear()
    }
  })
})
</script>

<style scoped>
.navbar-action {
  @apply transition-all duration-300 rounded hover:bg-bg_color hover:text-text_color hover:bg-opacity-80 mx-1 truncate;
}

.navbar-link {
  @apply flex items-center p-2;
}

.navbar-title {
  @apply ml-1 hidden md:inline font-normal;
}

.router-link-exact-active {
  @apply bg-bg_color text-text_color bg-opacity-80 rounded;
}

.bounce-enter-active {
  animation: bounce-in 1s;
}

@keyframes bounce-in {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.9);
  }

  75% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}
</style>
