<template>
  <div>
    <ThemeHeadStore />

    <Head v-if="store?.icon">
      <Link rel="icon" type="image/x-icon" :href="store.icon" />
    </Head>

    <PixelScript v-if="store?.facebook_info" :id="store.facebook_info" />

    <PwaPrompt />

    <slot />
  </div>
</template>

<script setup lang="ts">
import { useDomainCategoriesStore } from '~/store/domain_categories'
import type { Store } from '~/composables/useMenuModel'
import { usePlacesStore } from '~/store/places'
import getAllRoutes from '~/composables/AppApiRoutes'
const { storeModel, adModel } = useMenuModel()
const { getUuid, handleReponseApi } = useCartStore()
const { fetchCities, fetchAgentPlace } = usePlacesStore()
const { setTableToStore } = useCartMethods()
const { host } = useDomainHost()
const { store, setStore } = useDomainState()
const { setAds } = useAnnouncements()
const locale = useNuxtApp().$i18n?.locale?.value
const { isAuthLoggedIn } = useMenuModel()
const isAuth = isAuthLoggedIn()
const { resetValidCach } = useLocaleStore()
const route = useRoute()
const isReValidCach = useLocaleStore().isReValidCach
setStore(null)
const { data: domainStore, error } = await useBasicFetch(
  '/nuxt-api/laravel/store',
  {
    query: {
      id: host,
      app_locale: locale,
      domain: useDomainHeader(),
      isReValidCach
    },

    transform: (data: SuccessResponse<Store>) => data.data
  }
)
if (error.value) {
  createError(error.value)
}
if (domainStore.value) {
  await Promise.all([
    doseUserHaveTable(),
    useDomainCategoriesStore().fetchCategories(domainStore.value),
    useMaterialDefinitionsStore().fetchMaterialDefinitionsStore(
      domainStore.value
    ),
    useStoresSettings().fetchSettingsStore(domainStore.value),
    fetchCities(),
    fetchAds(),
    fetchAgentPlace()
  ])

  domainStore.value = storeModel(domainStore.value)
  await setTableStore(domainStore.value)
  setStore(domainStore.value)
  resetValidCach()
}
async function fetchAds() {
  const { data: ads } = await useBasicFetch(
    getAllRoutes().announcementRoutes.announcementsStore(domainStore.value!.id),
    {
      transform: (
        data: SuccessResponse<{
          announcementsStore: Ad[]
          announcements: Ad[]
        }>
      ) => {
        return data.data.announcementsStore.map(adModel)
      }
    }
  )
  setAds(ads.value || [])
}
async function doseUserHaveTable() {
  const { data: dataTemp } = await useBasicFetch(
    getAllRoutes(isAuth).storesRoutes.doseUserHaveTableWithToggle(
      domainStore.value!.id
    ),
    {
      method: 'POST',
      body: {
        app_locale: locale,
        store_id: domainStore.value!.id,
        client_uuid: getUuid() || undefined
      }
    }
  )
  domainStore.value!.is_user_have_table = dataTemp.value?.data
    ?.dose_user_have_table as any
}

async function setTableStore(storeValue: Store) {
  if (
    route.query.table_id &&
    storeValue?.id &&
    !storeValue.is_user_have_table
  ) {
    const dataApi = await setTableToStore(storeValue?.id, route.query.table_id)
    if (handleReponseApi(dataApi, storeValue?.id.toString())) {
      storeValue.is_user_have_table = true
    }
  }
}
</script>
