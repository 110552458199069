<template>
  <div
    v-if="settingsStore.length && isMobileOrTablet"
    class="e_store-container flex flex-wrap items-center text-text_alt_color justify-center mt-10"
  >
    <EStoreLink
      v-for="(link, index) in settingsStore"
      :key="`links-store-${index}`"
      :to="`/about/${link.key}`"
      class="navbar-action"
      :aria-label="t('aria_page_label', { page: link.key })"
    >
      <div class="navbar-link">{{ t(link.key) }}</div>
    </EStoreLink>
  </div>
</template>

<script setup lang="ts">
const { settingsStore } = useStoresSettings()
const { isMobileOrTablet } = useDevice()
const { t } = useI18n()
</script>

<style scoped>
.navbar-action {
  @apply transition-all duration-300 rounded hover:bg-bg_color  hover:text-text_color hover:bg-opacity-80 mx-1 truncate;
}
.navbar-link {
  @apply flex items-center p-2;
}
.router-link-exact-active {
  @apply bg-bg_color text-text_color bg-opacity-80 rounded;
}
</style>
