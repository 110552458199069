<template>
  <div
    class="flex flex-wrap items-center justify-center text-text_alt_color w-11/12 md:w-10/12 lg:w-8/12 mx-auto"
  >
    <div v-if="isMobileOrTablet" class="w-full m-5 text-start">
      <div class="flex items-center">
        <v-icon :size="28" class="block flex-shrink-0">
          <IconsViewDetails />
        </v-icon>
        <div v-if="store.about" class="mx-2">
          <div v-html="store.about"></div>
        </div>
      </div>

      <div v-if="isMobileOrTablet" class="flex items-center">
        <img
          v-if="informationStore.supportDelivery"
          src="/images/delivery.png"
          class="w-9 h-9"
        />
        <IconsShippingCarNoDelivery v-else class="w-7 h-7" />
        <div class="mx-2">
          <div v-if="informationStore.supportDelivery">
            {{ t('support_shipping') }}
          </div>
          <div v-else>{{ t('dose_not_support_shipping') }}</div>
        </div>
      </div>

      <a
        :href="`https://www.google.com/maps/search/?api=1&query=${informationStore.location?.lat},${informationStore.location?.lng}`"
        target="_blank"
        class="flex items-center"
      >
        <v-icon
          icon="mdi mdi-map-marker"
          :size="28"
          class="block flex-shrink-0"
        />
        <div class="mx-2">{{ informationStore.detailedAddress }}</div>
      </a>
    </div>

    <div
      v-else-if="informationStore.detailedAddress"
      class="w-full mx-5 my-2 sm:my-3 text-center"
    >
      <a
        :href="`https://www.google.com/maps/search/?api=1&query=${informationStore.location?.lat},${informationStore.location?.lng}`"
        target="_blank"
      >
        <div class="mb-3 mx-auto">
          <v-icon icon="mdi mdi-map-marker" class="custom-font-size" />
        </div>
        <div>{{ informationStore.detailedAddress }}</div>
      </a>
    </div>

    <div v-if="store.store_email" class="flex-1 mx-5 my-2 sm:my-3 text-center">
      <a :href="`mailto:${store.store_email}`" target="_blank">
        <div class="mb-3 mx-auto">
          <v-icon icon="mdi-email" class="custom-font-size" />
        </div>

        <div class="truncate flex-1">{{ store.store_email }}</div>
      </a>
    </div>

    <div
      v-if="parseFloat(informationStore.whatsappNumber ?? '')"
      class="flex-1 mx-5 my-2 sm:my-3 text-center"
    >
      <a :href="`tel:${informationStore.whatsappNumber}`" target="_blank">
        <div class="mb-3 mx-auto">
          <v-icon icon="mdi-phone" class="custom-font-size" />
        </div>
        <div class="truncate flex-1" dir="ltr">
          {{ informationStore.whatsappNumber }}
        </div>
      </a>
    </div>
    <div
      v-if="store.whats_app_channel"
      class="flex-1 mx-5 my-2 sm:my-3 text-center"
    >
      <a :href="store.whats_app_channel" target="_blank">
        <div class="mb-3 mx-auto">
          <v-icon class="custom-font-size">
            <IconsWhatsappChannel />
          </v-icon>
        </div>
        <div class="truncate flex-1">{{ t('whatsapp_channel') }}</div>
      </a>
    </div>

    <div v-if="store.facebook" class="flex-1 mx-5 my-2 sm:my-3 text-center">
      <a :href="store.facebook" target="_blank">
        <div class="mb-3 mx-auto">
          <v-icon icon="mdi-facebook" class="custom-font-size" />
        </div>
        <div class="truncate flex-1">{{ t('facebook') }}</div>
      </a>
    </div>

    <div v-if="store.instagram" class="flex-1 mx-5 my-2 sm:my-3 text-center">
      <a :href="store.instagram" target="_blank">
        <div class="mb-3 mx-auto">
          <v-icon icon="mdi-instagram" class="custom-font-size" />
        </div>
        <div class="truncate flex-1">{{ t('instagram') }}</div>
      </a>
    </div>
    <div v-if="store.tikTok" class="flex-1 mx-5 my-2 sm:my-3 text-center">
      <a :href="store.tikTok" target="_blank">
        <div class="mb-3 mx-auto">
          <v-icon icon="fa: fa-brands fa-tiktok" class="custom-font-size" />
        </div>
        <div class="truncate flex-1">{{ t('tikTok') }}</div>
      </a>
    </div>
    <div v-if="store.telegram" class="flex-1 mx-5 my-2 sm:my-3 text-center">
      <a :href="store.telegram" target="_blank">
        <div class="mb-3 mx-auto">
          <v-icon icon="fa: fa-brands fa-telegram" class="custom-font-size" />
        </div>
        <div class="truncate flex-1">{{ t('telegram') }}</div>
      </a>
    </div>
    <div v-if="store.twitter" class="flex-1 mx-5 my-2 sm:my-3 text-center">
      <a :href="store.twitter" target="_blank">
        <div class="mb-3 mx-auto">
          <v-icon icon="fa: fa-brands fa-twitter" class="custom-font-size" />
        </div>
        <div class="truncate flex-1">{{ t('twitter') }}</div>
      </a>
    </div>
    <div v-if="store.youtube" class="flex-1 mx-5 my-2 sm:my-3 text-center">
      <a :href="store.youtube" target="_blank">
        <div class="mb-3 mx-auto">
          <v-icon icon="fa: fa-brands fa-youtube" class="custom-font-size" />
        </div>
        <div class="truncate flex-1">{{ t('youtube') }}</div>
      </a>
    </div>
    <div v-if="store.snapchat" class="flex-1 mx-5 my-2 sm:my-3 text-center">
      <a :href="store.snapchat" target="_blank">
        <div class="mb-3 mx-auto">
          <v-icon icon="fa: fa-brands fa-snapchat" class="custom-font-size" />
        </div>
        <div class="truncate flex-1">{{ t('snapchat') }}</div>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Store } from '~~/composables/useMenuModel'

const props = defineProps<{ store: Store }>()

const { isMobileOrTablet } = useDevice()
const { t } = useI18n()

const informationStore = computed(() => {
  return {
    detailedAddress: props.store?.detailed_address,
    location: props.store?.location,
    whatsappNumber: props.store?.whats_app_number,
    supportDelivery: props.store?.support_delivery
  }
})
</script>
<style scoped>
.v-icon--size-default.custom-font-size {
  @apply text-[28px] sm:text-[40px] text-text_alt_color !important;
}
</style>
