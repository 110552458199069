<template>
  <ProvidersDomain>
    <v-app class="custom-bg">
      <v-locale-provider :rtl="useIsRtl()">
        <v-layout style="display: block">
          <div v-if="store" :style="backgroundStyle">
            <header class="w-full fixed top-0 z-40">
              <LayoutNavbarDomain class="w-full">
                <button
                  :aria-label="t('open_drawer')"
                  class="ltr:mr-2 rtl:ml-2"
                  @click="isOpen = true"
                >
                  <v-icon
                    icon="mdi mdi-menu"
                    :size="24"
                    class="text-text_color"
                  />
                </button>
              </LayoutNavbarDomain>

              <DevHint v-if="devHint" />
            </header>

            <v-navigation-drawer
              v-model="isOpen"
              location="right"
              temporary
              class="custom"
            >
              <LayoutNavbarDrawer @close="isOpen = false" />
            </v-navigation-drawer>

            <main>
              <div class="h-12 sm:my-1"></div>
              <slot />
            </main>

            <footer>
              <LayoutWhatsapp
                v-if="!isMobileOrTablet && whatsappNumber"
                class="z-40"
                :whatsapp-number="whatsappNumber"
              />

              <LayoutBottomNavigation
                v-if="isMobileOrTablet && !routeIsForMaterialPage()"
                :show-cart="!!store.cart_visible"
                :whatsapp-number="whatsappNumber"
                class="fixed w-full bottom-0 z-40"
              />

              <div class="bg-nav_color sm:py-5">
                <LayoutFooterActionsSocialMedia :store="store" />

                <LayoutFooterActionsSettings />
                <div class="h-20"></div>
              </div>
            </footer>
          </div>
        </v-layout>
      </v-locale-provider>
    </v-app>
  </ProvidersDomain>
</template>

<script setup lang="ts">
const { store } = useDomainState()
const { url } = useDomainHost()
const { routeIsForMaterialPage } = useMenuModel()
const { isMobileOrTablet } = useDevice()
const isOpen = ref(false)

const { t } = useI18n()
const devHint = ref(process.env.NUXT_PUBLIC_PLATFORM === 'dev.e-store.biz')

const backgroundStyle = computed(() => {
  return store.value?.bg_image
    ? {
        backgroundImage: `url('/_cache_ipx/fit_cover&f_webp&q_80&loading_lazy&w_1000/${store.value?.bg_image}')`,
        backgroundSize: '100%',
        backgroundRepeat: 'repeat-y'
      }
    : {}
})
const whatsappNumber = computed(() => {
  return useWhatsappNumer(
    false,
    store.value || undefined
  )
})

useHead({
  script: [useSeoLogo(url, store.value?.icon)]
})
</script>

<style scoped>
.v-navigation-drawer.custom {
  @apply bg-bg_color !important;
}
.custom-bg {
  @apply bg-bg_color text-text_color !important;
}
.nav-section {
  @apply flex-1 bg-nav_color flex items-center justify-evenly;
}
.nav-section .link {
  @apply p-2 transition-all duration-500;
}
.nav-section .link.router-link-exact-active {
  @apply bg-text_alt_color text-nav_color;
}
</style>
