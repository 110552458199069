// Change hex color into RGB /////////////////////////////////////////////////////////////////////
export const useGetRGBColor = (hex: string, type: any) => {
  const color = hex.replace(/#/g, '')
  // rgb values
  const r = parseInt(color.substr(0, 2), 16)
  const g = parseInt(color.substr(2, 2), 16)
  const b = parseInt(color.substr(4, 2), 16)

  return `--color-${type}: ${r}, ${g}, ${b};`
}

export const useGetAccessibleColor = (hex: string) => {
  const color = hex.replace(/#/g, '')
  // rgb values
  const r = parseInt(color.substr(0, 2), 16)
  const g = parseInt(color.substr(2, 2), 16)
  const b = parseInt(color.substr(4, 2), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? '#000000' : '#FFFFFF'
}


