import type { LocaleObject } from '@nuxtjs/i18n/dist/runtime/composables'
import { defineStore } from 'pinia'

export const useLocaleStore = defineStore('Locale', {
  state: (): {
    localeLan: string
    isReValidCach: boolean
  } => ({
    localeLan: 'ar',
    isReValidCach: false
  }),

  actions: {
    setLocale(locale: any) {
      const switchLocalePath = useSwitchLocalePath()
      const router = useRouter()

      this.localeLan = locale
      router.replace(switchLocalePath(locale))
    },
    resetValidCach() {
      this.isReValidCach = false
    },

    setLocaleWithRestart(locale: any) {
      this.localeLan = locale
      this.isReValidCach = true
      const switchLocalePath = useSwitchLocalePath()
      window.history.pushState({}, '', switchLocalePath(locale))
      window.location.reload()
    }
  },
  persist: [{ paths: ['reValidCach'], storage: persistedState.cookies }]
})

export const useLocales = (): LocaleObject[] => {
  const { store } = useDomainState()
  const { locales } = useI18n()

  if (store.value?.allowed_languages?.length) {
    return (locales.value as LocaleObject[])?.filter((el) => {
      return store.value?.allowed_languages.includes(el.code)
    })
  }

  return [] as LocaleObject[]
}
